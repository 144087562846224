<template lang="pug">
  .datepicker-filter
    .tip {{ title }}
    AppDatepicker.datepicker(
      v-bind="$props"
      :range="true"
      :disabled-date="isDisabledDate"
      :clearable="false"
      @change="$emit('change', $event)"
    )
</template>

<script>
  import { format } from "date-fns"
  import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
  import { mapGetters } from "vuex"

  export default {
    components: {
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    props: {
      value: {
        type: Array,
        default: () => new Array()
      },
      minDate: {
        type: Date,
        required: true
      },
      maxDate: {
        type: Date,
        required: true
      }
    },

    computed: {
      ...mapGetters(["isNicolasEnabled"]),

      title({ isNicolasEnabled, formattedAllowedMaxDate }) {
        const key = isNicolasEnabled ? "you_can_set_till_180" : "you_can_set_till"

        return this.$t(`inventory_management.inventory_long_period.date_filter.${key}`, {
          date: formattedAllowedMaxDate
        })
      },

      formattedAllowedMaxDate({ maxDate }) {
        return format(maxDate, DATE_FNS_DATE_FORMAT)
      }
    },

    methods: {
      isDisabledDate(date) {
        return date < this.minDate || date > this.maxDate
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .datepicker-filter
    width: 49%

    .tip
      color: $default-gray-medium
      font-size: 0.8rem
      margin-bottom: 5px

    .datepicker
      width: 100%
</style>
